import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

function Terms() {
  return (
    <Layout>
      <SEO title="Terms" />
      <div style={{ maxWidth: 800 }} className="mx-auto">
        <h1 className="text-3xl text-quod-green-600 font-bold inline-block my-12 p-3">
          Terms of Use Agreement
        </h1>
        <p className="mb-3 p-3">
          This Terms of Use Agreement is effective as of May 6th 2020
        </p>
        <p className="mb-3 p-3">
          Welcome to the quod website (quodqh.com the "Site"). dreamm ltd
          ("dreamm" or "We") maintain(s) this Site as a service to our visitors
          and customers. By accessing or using the Site (or any applications,
          including mobile applications made available by dreamm), you are
          agreeing to comply with and be bound by the following terms of use.
          These terms of use affect your legal rights and obligations and form a
          binding agreement between dreamm and you (the "Agreement"). Please
          review the following Agreement terms carefully. If you do not agree to
          these terms, you should not review information or obtain goods or
          products from this Site.
        </p>
        <h2 className="text-2xl text-quod-green-600 font-bold inline-block my-8 p-3">
          Basic Terms
        </h2>
        <div className="mb-3 p-3">
          In using the Site, you agree to the following basic terms:
          <ul>
            <li className="p-3"></li>
            <li className="p-3">
              You must be at least 18 years old to use the Site.
            </li>
            <li className="p-3">
              You may not post violent, discriminatory, unlawful, infringing,
              hateful, pornographic or sexually suggestive content via the Site.
            </li>
            <li className="p-3">
              You are responsible for any activity that occurs through your
              account and you agree you will not sell, transfer, license or
              assign your account, followers, username, or any account rights.
              With the exception of people or businesses that are expressly
              authorized to create accounts on behalf of their employers or
              clients, dreamm prohibits the creation of and you agree that you
              will not create an account for anyone other than yourself. You
              also represent that all information you provide or provided to
              dreamm upon registration and at all other times will be true,
              accurate, current and complete, and you agree to update your
              information as necessary to maintain its truth and accuracy.
            </li>
            <li className="p-3">
              You agree that you will not solicit, collect or use the login
              credentials of other quod users.
            </li>
            <li className="p-3">
              You are responsible for keeping your password secret and secure.
            </li>
            <li className="p-3">
              You must not defame, stalk, bully, abuse, harass, threaten,
              impersonate or intimidate people or entities and you must not post
              private or confidential information via the Site, including your
              or any other person's credit card information, social security or
              alternate national identity numbers, non-public phone numbers or
              non-public email addresses.
            </li>
            <li className="p-3">
              You may not use the Site for any illegal or unauthorized purpose.
              You agree to comply with all laws, rules and regulations (for
              example, national, federal, state, local and provincial)
              applicable to your use of the Site and your Content (defined
              below), including copyright laws.
            </li>
            <li className="p-3">
              You are solely responsible for your conduct and any data, text,
              files, information, usernames, images, graphics, photos, profiles,
              audio and video clips, sounds, musical works, works of authorship,
              applications, links and other content or materials (collectively,
              "User Content") that you submit, post or display on or via the
              Site.
            </li>
            <li className="p-3">
              You must not change, modify, adapt or alter the Site or change,
              modify or alter another website so as to falsely imply that it is
              associated with the Site or dreamm.
            </li>
            <li className="p-3">
              You must not create or submit unwanted email, comments, likes or
              other forms of commercial or harassing communications (a/k/a
              "spam") to any quod users.
            </li>
            <li className="p-3">
              You must not use domain names or web URLs in your username without
              prior written consent from dreamm.
            </li>
            <li className="p-3">
              You must not interfere or disrupt the Site or servers or networks
              connected to the Site, including by transmitting any worms,
              viruses, spyware, malware or any other code of a destructive or
              disruptive nature. You may not inject content or code or otherwise
              alter or interfere with the way any dreamm page is rendered or
              displayed in a user's browser or device.
            </li>
            <li className="p-3">
              You must not create accounts with the Site (or otherwise attempt
              to access the Site or any content or other material on the Site)
              through unauthorized means, including by using an automated
              device, script, bot, spider, crawler or scraper.
            </li>
            <li className="p-3">
              You must not use the Site in any manner that could disable,
              overburden, damage, or impair the Site, or interfere with any
              other use of the Site.
            </li>
            <li className="p-3">
              You must not attempt to restrict another user from using or
              enjoying the Site and you must not encourage or facilitate
              violations of this Agreement or any other dreamm terms.
            </li>
            <li className="p-3">
              Violation of this Agreement may, at dreamm’s sole discretion,
              result in termination of your quod account and/or access to the
              Site. You understand and agree that dreamm cannot and will not be
              responsible for the User Content posted on the Site, and you use
              the Site at your own risk. If you violate the letter or spirit of
              this Agreement, or otherwise create risk or possible legal
              exposure for dreamm, we can stop providing all or part of the Site
              to you.
            </li>
          </ul>
        </div>
        <h2 className="text-2xl text-quod-green-600 font-bold inline-block my-8 p-3">
          General Terms and Conditions
        </h2>
        <p>1. Scope of Agreement</p>
        <p className="mb-3 p-3">
          This Agreement constitutes the entire and only agreement between us
          and you, and supersedes all prior or contemporaneous agreements,
          representations, warranties, and understandings with respect to the
          Site; the content, products, or services provided by or through the
          Site; and the subject matter of this Agreement. dreamm reserves the
          right, in its sole discretion, to change this Agreement ("Updated
          Agreement") from time to time. Unless we make a change for legal or
          administrative reasons, we will provide reasonable advance notice
          before the Updated Agreement becomes effective. You agree that we may
          notify you of the Updated Agreement by posting it on the Site, and
          that your use of the Site after the effective date of the Updated
          Agreement (or engaging in such other conduct as we may reasonably
          specify) constitutes your agreement to the Updated Agreement.
          Therefore, you should review this Agreement and any Updated Agreement
          before using the Site. The Updated Agreement will be effective as of
          the time of posting, or such later date as may be specified in the
          Updated Agreement, and will apply to your use of the Site from that
          point forward. This Agreement will govern any disputes arising before
          the effective date of the Updated Agreement. We reserve the right to
          modify, suspend, or terminate access to the Site for any reason,
          without notice, at any time, and without liability to you. If we
          terminate your access to the Site, your User Content may no longer be
          accessible, but it may persist and appear on the Site (e.g., if your
          User Content has been reshared by others).
        </p>
        <p className="mb-3 p-3">2. dreamm Content and User Content</p>
        <p className="mb-3 p-3">
          The Site contains content owned or licensed by dreamm ("dreamm
          Content"). dreamm Content is protected by copyright, trademark,
          patent, trade secret and other laws, and, as between you and dreamm,
          dreamm owns and retains all rights in the dreamm Content and the Site.
          There are two types of User Content: (a) downloadable assets, and (b)
          articles.
        </p>
        <p className="mb-3 p-3">
          (a) Downloadable Assets. An example of a downloadable asset is a
          PowerPoint template that dreamm provides. You will have a limited,
          nonexclusive, nontransferable, terminable right to use, reproduce,
          display, and distribute downloadable assets in a commercial work
          (including the right to showcase it as part of your portfolio). All
          other rights are reserved. Accordingly, you may not distribute,
          modify, or host downloadable assets as your own content (or permit any
          third party to do so).
        </p>
        <p className="mb-3 p-3">
          (b) Articles. Examples of articles are short-form articles and
          longer-form e-books. You will have a limited, nonexclusive,
          nontransferable, terminable right to use, reproduce, and distribute
          articles, provided that you provide appropriate attribution, as
          follows. In connection with any distribution of an article, you must
          supply dreamm's and the article author's name as the providers of the
          article. You may not use articles for commercial purposes, meaning any
          purpose intended for commercial advantage or monetary compensation.
          You are not permitted to create derivative works of articles. If you
          remix, transform, or build upon an article, you may not distribute the
          modified material. You may not apply legal terms or technological
          measures that legally restrict others from using the articles (or any
          form thereof) in any manner that is otherwise permitted by this
          license.
        </p>
        <p className="mb-3 p-3">
          There are no implied licenses hereunder, and any right not expressly
          granted are reserved by dreamm and its licensors. Without limiting the
          generality of the foregoing, you will not remove, alter or conceal any
          copyright, trademark, service mark or other proprietary rights notices
          incorporated in or accompanying the dreamm Content, and you will not
          reproduce, modify, adapt, prepare derivative works based on, perform,
          display, publish, distribute, transmit, broadcast, sell, license or
          otherwise exploit the dreamm Content. If you post User Content to the
          Site, then the following terms apply.
        </p>
        <p className="mb-3 p-3">
          dreamm does not claim ownership of any User Content that you post on
          or through the Site. Instead, you hereby grant to dreamm a
          non-exclusive, fully paid and royalty-free, transferable,
          sub-licensable, worldwide license to use the User Content that you
          post on or through the Site, for all purposes described in and
          contemplated by this Agreement, as well as for dreamm's publicity
          purposes (including as part of a user testimonial on the Site). You
          represent and warrant that: (i) you own the User Content posted by you
          on or through the Site, or otherwise have the right to grant the
          rights and licenses set forth in this Agreement; (ii) the posting and
          use of your User Content on or through the Site does not violate,
          misappropriate or infringe on the rights of any third party, including
          privacy rights, publicity rights, copyrights, trademark and/or other
          intellectual property rights; (iii) you agree to pay for all
          royalties, fees, and any other monies owed by reason of User Content
          you post on or through the Site; and (iv) you have the legal right and
          capacity to enter into this Agreement in your jurisdiction. The
          copying, redistribution, use, or publication by you of any such
          matters or any part of the Site, except as allowed by Section 4, is
          strictly prohibited. You do not acquire ownership rights to any dreamm
          Content, or other user content, document, or other materials that you
          view (but do not post) through the Site. The posting of User Content
          on the Site does not constitute a waiver of any right in such
          information and materials.
        </p>
        <p className="mb-3 p-3">3. License termination</p>
        <p className="mb-3 p-3">
          Upon termination of this Agreement, all licenses and other rights
          granted to you under this Agreement will immediately cease.
        </p>
        <p className="mb-3 p-3">4. Editing, Deleting, and Modification</p>
        <p className="mb-3 p-3">
          You agree that dreamm is not responsible for, and does not endorse,
          Content posted within the Site. dreamm does not have any obligation to
          prescreen, monitor, edit, or remove any Content. If your Content
          violates this Agreement, you may bear legal responsibility for that
          Content. You agree that any Content will be non-confidential and
          non-proprietary and we will not be liable for any use or disclosure of
          Content. You acknowledge and agree that your relationship with dreamm
          is not a confidential, fiduciary, or other type of special
          relationship, and that your decision to submit any Content does not
          place dreamm in a position that is any different from the position
          held by members of the general public, including with regard to your
          Content. None of your Content will be subject to any obligation of
          confidence on the part of dreamm, and dreamm will not be liable for
          any use or disclosure of any Content you provide. Without limiting the
          generality of the foregoing, we reserve the right in our sole
          discretion to edit or delete any documents, information, or other
          content appearing on the Site.
        </p>
        <p className="mb-3 p-3">5. Indemnification</p>
        <p className="mb-3 p-3">
          You (and also any third party for whom you operate an account or
          activity on the Site) agree to defend (at dreamm’s request), indemnify
          and hold dreamm and its affiliates and their employees, officers,
          directors, agents, successors and assigns (collectively, the "dreamm
          Parties") harmless from and against any claims, liabilities, damages,
          losses, and expenses, including reasonable legal fees and costs,
          arising out of or in any way connected with any of the following
          (including as a result of your direct activities on the Site or those
          conducted on your behalf): (i) your User Content or your access to or
          use of the Site; (ii) your breach or alleged breach of this Agreement;
          (iii) your violation of any third-party right, including any
          intellectual property right, publicity, confidentiality, property or
          privacy right; (iv) your violation of any laws, rules, regulations,
          codes, statutes, ordinances or orders of any governmental and
          quasi-governmental authorities, including all regulatory,
          administrative and legislative authorities; or (v) any
          misrepresentation made by you. You will cooperate as fully required by
          dreamm in the defense of any claim. dreamm reserves the right to
          assume the exclusive defense and control of any matter subject to
          indemnification by you, and you will not in any event settle any claim
          without the prior written consent of dreamm.
        </p>
        <p className="mb-3 p-3">6. Assignment</p>
        <p className="mb-3 p-3">
          Your right to use the Site and/or any licenses granted under this
          Agreement are not assignable or transferable, including by operation
          of law or otherwise. Any attempted assignment or transfer in violation
          of the foregoing is null and void.
        </p>
        <p className="mb-3 p-3">7. Warranty Disclaimer</p>
        <p className="mb-3 p-3">
          The information from or through the public areas of this site is
          provided "as-is" and "as available" to the fullest extent possible by
          law. To the fullest extent possible by law, the dreamm parties, on
          behalf of themselves and any and all third party user content
          providers, do not make any representations or warranties or
          endorsements of any kind whatsoever, express or implied or statutory
          as to the site, dreamm content, any third party user content, or any
          security associated with the transmission of information to dreamm or
          via the site, all of which are hereby disclaimed (including the
          disclaimer of any implied warranties of merchantability, fitness for a
          particular purpose, title, non-infringement, custom, trade, quiet
          enjoyment, system integration, and freedom from computer virus). The
          information and site may contain bugs, errors, problems, or other
          limitations. The dreamm parties on behalf of themselves and any and
          all third party user content providers, do not represent or warrant
          that the site or third party user content will be error-free or
          uninterrupted; that defects will be corrected; or that the site or the
          server that makes the site available is free from any harmful
          components, including viruses and other forms of malware. The dreamm
          parties do not make any representations or warranties that the
          information (including any instructions and/or third party user
          content) on the site is accurate, complete, or useful. You acknowledge
          that your use of the site is at your sole risk. The dreamm parties on
          behalf of themselves and any and all third party user content
          providers,do not warrant that your use of the site or third party user
          content is lawful in any particular jurisdiction, and the dreamm
          parties, on behalf of themselves and any and all third party user
          content providers,specifically disclaim such warranties. Some
          jurisdictions limit or do not allow the disclaimer of implied or other
          warranties, so the above disclaimer may not apply to you to the extent
          such jurisdiction's law is applicable to you and this agreement.
        </p>
        <p className="mb-3 p-3">
          By accessing or using the site, you represent and warrant that your
          activities are lawful in every jurisdiction where you access or use
          the site.
        </p>
        <p className="mb-3 p-3">
          The dreamm parties do not endorse third party user content and
          specifically disclaim any responsibility or liability to any person or
          entity for any loss, damage (whether actual, consequential, punitive
          or otherwise), injury, claim, liability or other cause of any kind or
          character based upon or resulting from any third party user content.
        </p>
        <p className="mb-3 p-3">8. Limitation of Liability</p>
        <p className="mb-3 p-3">
          Under no circumstances will the dreamm parties be liable to you for
          any loss or damages of any kind (including for any direct, indirect,
          economic, exemplary, special, punitive, incidental or consequential
          losses or damages) that are directly or indirectly related to: (a) the
          site; (b) the dreamm content; (c) user content; (d) your use of,
          inability to use, or the performance of the site; (e) any action taken
          in connection with an investigation by the dreamm parties or law
          enforcement authorities regarding your or any other party's use of the
          site; (f) any action taken in connection with copyright or other
          intellectual property owners; (g) any errors or omissions in the
          site's operation; or (h) any damage to any user's computer, mobile
          device, or other equipment or technology including damage from any
          security breach or from any virus, bugs, tampering, fraud, error,
          omission, interruption, defect, delay in operation or transmission,
          computer line or network failure or any other technical or other
          malfunction, including damages for lost profits, loss of goodwill,
          loss of data, work stoppage, accuracy of results, or computer failure
          or malfunction, even if foreseeable or even if the dreamm parties have
          been advised of or should have known of the possibility of such
          damages, whether in an action of contract, negligence, strict
          liability or tort (including whether caused in whole or in part by
          negligence, acts of god, telecommunications failure, or theft or
          destruction of the site). In no event will the dreamm parties be
          liable to you or anyone else for loss, damage or injury, including
          death or personal injury. Some states do not allow the exclusion or
          limitation of incidental or consequential damages, so the above
          limitation or exclusion may not apply to you. In no event will the
          dreamm parties' total liability to you for all damages, losses or
          causes or action exceed one hundred pounds sterling (GBP100.00)
        </p>
        <p className="mb-3 p-3">
          You agree that in the event you incur any damages, losses, or injuries
          that arise out of dreamm’s acts or omissions, the damages, if any,
          caused to you are not irreparable or so insufficient that they might
          entitle you to an injunction preventing any exploitation of any site,
          service, property, product or other content owned or controlled by the
          dreamm parties, and you will have no rights to enjoin or restrain the
          development, production, distribution, advertising, exhibition or
          exploitation of any site, property, product, service, or other content
          owned or controlled by the dreamm parties.
        </p>
        <p className="mb-3 p-3">
          By accessing the site, you understand that you may be waiving rights
          with respect to claims that are at this time unknown or unsuspected.
          Dreamm is not responsible for the actions, content, information, or
          data of third parties, and you release all dreamm parties from any
          claims and damages, known and unknown, arising out of or in any way
          connected with any claim you have against any such third parties.
        </p>
        <p className="mb-3 p-3">9. Use of Information; Feedback</p>
        <p className="mb-3 p-3">
          We reserve the right, and you authorize us, to use and assign of all
          information regarding Site uses by you and all information provided by
          you in any manner, consistent with our Privacy Policy. It is dreamm's
          policy not to accept or consider content, information, ideas,
          suggestions or other materials other than those we have specifically
          requested and to which certain specific terms, conditions and
          requirements may apply. This is to avoid any misunderstandings if your
          ideas are similar to those we have developed or are developing
          independently. Accordingly, dreamm does not accept unsolicited
          materials or ideas, and takes no responsibility for any materials or
          ideas so transmitted. If, despite our policy, you choose to send us
          content (other than “Content”), information, ideas, suggestions, or
          other materials, you further agree that dreamm is free to use any such
          content, information, ideas, suggestions or other materials, for any
          purposes whatsoever, including developing and marketing products and
          services, without any liability or payment of any kind to you.
        </p>
        <p className="mb-3 p-3">10. Privacy Policy</p>
        <p className="mb-3 p-3">
          Our <a href="http://dreamm.co.uk/legal">Privacy Policy</a> also
          governs your use of this site. You are encouraged to review it for
          information regarding our collection, use, and sharing of the
          information we collect.
        </p>
        <p className="mb-3 p-3">11. Payments</p>
        <p className="mb-3 p-3">
          You represent and warrant that if you are purchasing something from us
          that (i) any credit information you supply is true and complete, (ii)
          charges incurred by you will be honored by your credit card company,
          and (iii) you will pay the charges incurred by you at the posted
          prices, including any applicable taxes.
        </p>
        <p className="mb-3 p-3">12. Links to Other Web sites</p>
        <p className="mb-3 p-3">
          The Site contains links to other websites. We are not responsible for
          the content, accuracy, or opinions express, in such websites, and such
          websites are not investigated, monitored, or checked for accuracy or
          completeness by us. Inclusion of any linked website on our Site does
          not imply approval or endorsement of the linked website by us. If you
          decide to leave our Site and access these third-party sites, you do so
          at your own risk.
        </p>
        <p className="mb-3 p-3">13. Information and Press Releases</p>
        <p className="mb-3 p-3">
          The Site contains information and press releases about us. While this
          information was believed to be accurate as of the date prepared, we
          disclaim any duty or obligation to update this information or any
          press releases. Information about companies other than ours contained
          in the press release or otherwise, should not be relied upon as being
          provided or endorsed by us.
        </p>
        <p className="mb-3 p-3">14. Territorial Restrictions</p>
        <p className="mb-3 p-3">
          The information provided within the Site is not intended for
          distribution to or use by any person or entity in any jurisdiction or
          country where such distribution or use would be contrary to law or
          regulation or which would subject dreamm to any registration
          requirement within such jurisdiction or country. We reserve the right
          to limit the availability of the Site or any portion of the Site, to
          any person, geographic area, or jurisdiction, at any time and in our
          sole discretion, and to limit the quantities of any content, program,
          product, service or other feature that dreamm provides.
        </p>
        <p className="mb-3 p-3">
          The effective date of these Terms of Use is set forth above. This
          Agreement were written in English. To the extent any translated
          version of this Agreement conflicts with the English version, the
          English version controls.
        </p>
      </div>
    </Layout>
  )
}

export default Terms
